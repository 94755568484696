import { Stats } from "./interfaces";
import * as React from "react";
import {
  HeartIcon,
  FireIcon,
  TrophyIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/24/solid";
import { HeartIcon as HeathIconOutline } from "@heroicons/react/24/outline";
import { Tooltip } from "react-daisyui";
import * as classNames from "classnames";

const Icons = {
  health: HeartIcon,
  strength: FireIcon,
  karma: TrophyIcon,
  gold: CurrencyDollarIcon,
};

const healthLevel = (
  value: number,
): "max" | "high" | "medium" | "low" | "dead" => {
  if (value >= 10) {
    return "max";
  }
  if (value >= 7) {
    return "high";
  }
  if (value >= 4) {
    return "medium";
  }
  if (value >= 1) {
    return "low";
  }
  return "dead";
};

const HealthIcon = ({ value }: { value: number }) => {
  const level = healthLevel(value);
  const iconClass = classNames("absolute left-0 bottom-0 w-6 h-6 text-white", {
    "text-[#53AA85]": level === "max",
    "text-[#4B967C]": level === "high",
    "text-[#AA9C53]": level === "medium",
    "text-[#91293B]": level === "low",
    "text-[#3C1518]": level === "dead",
  });

  return (
    <div className="relative w-6 h-6">
      <HeathIconOutline className={iconClass} />
      <span
        className={classNames("absolute overflow-hidden left-0 bottom-0 w-6", {
          "h-6": level === "max",
          "h-4": level === "high",
          "h-3": level === "medium",
          "h-2": level === "low",
          "h-0": level === "dead",
        })}
      >
        <HeartIcon className={iconClass} />
      </span>
    </div>
  );
};

export function AudioBookStats({ currentStats }: { currentStats?: Stats }) {
  if (!currentStats) {
    return null;
  }

  return (
    <div className="flex flex-row gap-2">
      {Object.keys(currentStats).map((stat: keyof Stats) => {
        const Icon = Icons[stat as keyof Stats];
        return (
          <div key={stat} className="flex flex-col justify-center items-center">
            {stat === "health" ? (
              <>
                <Tooltip
                  message={`health hidden value: ${currentStats[stat as keyof Stats]}`}
                >
                  <HealthIcon value={currentStats[stat as keyof Stats]} />
                </Tooltip>
                &nbsp;
              </>
            ) : (
              <>
                <Tooltip message={stat}>
                  <Icon className="w-6 h-6 flex-none" />
                </Tooltip>
                {currentStats[stat as keyof Stats]}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}
