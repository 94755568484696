import * as React from "react";
import useSWR from "swr";
import { Log, LogLine } from "./LogLine";
import { Badge, Loading } from "react-daisyui";
import { ConfirmationButton } from "../../ds/ConfirmationButton";
import * as Cookies from "js-cookie";
import { ArchiveBoxXMarkIcon } from "@heroicons/react/24/outline";
import ConfirmOnExit from "../ConfirmOnExit";
import { ComponentProps, ReactNode, useEffect, useState } from "react";

interface MediaFile {
  label: string;
  url: string;
}

type Task =
  | {
  status: "PENDING";
  nRunning: number;
  nWaiting: number;
  logs: Log[];
}
  | {
  status: "STARTED" | "REVOKED";
  logs: Log[];
}
  | {
  status: "FAILURE"
  result: string;
  logs: Log[];
}
  | {
  status: "SUCCESS";
  result: object | null;
  logs: Log[];
};

function TaskResult({ result }: { result: object }) {
  if (result == null) {
    return <span className="italic">Task completed without result.</span>;
  }
  if ("mediaFiles" in result) {
    return (
      <div className="flex flex-col gap-2 justify-items-start">
        {(result.mediaFiles as MediaFile[]).map((mediaFile) => (
          <div key={mediaFile.url}>
            <a
              className="relative inline-flex items-center bg-gray-800 rounded px-3 py-2 text-sm text-gray-100 ring-1 ring-inset ring-gray-700 hover:bg-gray-900 focus:z-10"
              href={"/media/" + mediaFile.url}
              target="_blank"
            >
              Download {mediaFile.label}
            </a>
          </div>
        ))}
      </div>
    );
  }
  return <div>{JSON.stringify(result, null, 2)}</div>;
}

function computeDuration(nowCreated: number, prevCreated: number): string {
  const duration = nowCreated - prevCreated;
  const precision = duration > 10 ? 1 : 2;
  const multiplier = 10 * precision;
  const durationAtPrecision =
    Math.round((nowCreated - prevCreated) * multiplier) / multiplier;
  return durationAtPrecision.toLocaleString(undefined, {
    minimumIntegerDigits: 1,
    minimumFractionDigits: precision
  });
}

export function CeleryTask({
                             taskId,
                             onResult,
                             renderResult = (obj) => <TaskResult result={obj} />,
                             refreshInterval = 1000
                           }: {
  taskId: string;
  onResult?: (obj: object) => void;
  renderResult?: (obj: object) => ReactNode;
  refreshInterval?: number;
}) {
  const [polling, setPolling] = useState(true);
  const [terminating, setTerminating] = useState(false);
  const [userScrolled, setUserScrolled] = useState(false);

  const { data, error, isLoading } = useSWR<Task>(
    ["task", taskId],
    () =>
      fetch(`/api/task/${taskId}`).then((r) => {
        if (r.ok) {
          return r.json();
        }
        throw Error("Could not fetch task: error code " + r.status);
      }),
    { refreshInterval: polling ? refreshInterval : 0 }
  );

  useEffect(() => {
    // Detect when user scrolls manually
    const handleScroll = () => {
      const distanceFromBottom =
        document.documentElement.scrollHeight -
        window.innerHeight -
        window.scrollY;

      // Consider "near bottom" if within 20 pixels of the bottom
      const isNearBottom = distanceFromBottom < 20;

      if (!isNearBottom && !userScrolled) {
        setUserScrolled(true);
      }

      if (isNearBottom && userScrolled) {
        setUserScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [userScrolled]);

  useEffect(() => {
    // Auto-scroll only if user hasn't scrolled up
    if (!userScrolled) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [data ? data.logs : [], userScrolled]);

  const taskEnded =
    data && (data.status === "SUCCESS" || data.status === "FAILURE" || data.status === "REVOKED");

  if (polling && (error || taskEnded)) {
    setPolling(false);
    if (data.status === "SUCCESS" && onResult) {
      setTimeout(() => onResult(data.result), 100);
    }
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (isLoading) {
    return <div>Task sent, checking status...</div>;
  }

  const terminate = async () => {
    setTerminating(true)
    await fetch(
      `/api/task/${taskId}/terminate`,
      {
        method: "POST",
        headers: { "x-csrftoken": Cookies.get("csrftoken"), accept: "application/json" },
      }
    )
  }

  const badgeColor = ((): ComponentProps<typeof Badge>["color"] => {
    switch (data.status) {
      case "SUCCESS":
        return "success";
      case "PENDING":
        return "warning";
      case "STARTED":
        return "info";
      case "FAILURE":
        return "error";
      case "REVOKED":
        return "warning";
      default:
        return "warning";
    }
  })();

  return (
    <>
      <div className="mb-5 pb-5 border-b border-gray-700 flex justify-end items-center gap-2">
        <Badge color={badgeColor}>{data.status}</Badge>
        {!taskEnded && (
          <ConfirmationButton disabled={terminating} size="xs" color="error" onClick={terminate}>
            <ArchiveBoxXMarkIcon className="w-4 h-4" />
            {terminating ? "Terminating" : "Terminate"}
          </ConfirmationButton>
        )}
      </div>
      <div>
        {data && data.logs.length > 0 && (
          data.logs.map((log, i) => (
            <LogLine
              key={log.created}
              log={log}
              duration={
                i > 0
                  ? computeDuration(log.created, data.logs[i - 1].created)
                  : "0.00"
              }
            />
          ))
        )}
      </div>
      <div className="flex flex-row mt-5 pt-5 border-t border-gray-700 gap-2">
        <div className="flex flex-row gap-2 grow">
          {polling && <Loading size="md" className="mr-2" />}
          {polling && data && data.status === "PENDING" &&
            <span
              className="italic">Tasks in front: {data.nRunning + data.nWaiting} ({data.nRunning} currently running, {data.nWaiting} waiting before you)</span>}
          {polling && terminating && <span className="text-amber-500 italic">Terminating...</span>}
          {polling && <ConfirmOnExit />}
          {taskEnded && data.status === "FAILURE" && (
            <div className="text-red-500">{data.result}</div>
          )}
          {taskEnded && data.status === "SUCCESS" && renderResult(data.result)}
          {taskEnded && data.status === "REVOKED" &&
            <div className="text-amber-500 italic">Task was terminated by user.</div>}
        </div>
        {polling && (
          <div className="text-sm text-gray-500 right flex justify-end">
            Auto-scroll is {userScrolled ? "disabled" : "enabled"}
          </div>
        )}
      </div>
    </>
  );
}
