import * as React from "react";
import { useRef, useState } from "react";
import { Book, FeatureName, NextRoll, PageData, Stats } from "./interfaces";
import { useParams, useNavigate } from "react-router-dom";
import useSWR from "swr";
import { bookFetcher } from "../database";
import { AudioBookStats } from "./AudioBookStats";
import { AudioBookStep, HistoryStep } from "./AudioBookStep";
import { AudioBookNext } from "./AudioBookNext";
import { InputWithSearch } from "../InputWithSearch";
import { useStateLS } from "../../utils";
import { Button, Loading } from "react-daisyui";
import {
  MusicalNoteIcon,
  HeartIcon,
  ArrowRightOnRectangleIcon,
} from "@heroicons/react/24/outline";

const Feature = ({
  name,
  optional,
}: {
  name: FeatureName;
  optional?: boolean;
}) => {
  const Icon = (() => {
    switch (name) {
      case "health-points":
        return HeartIcon;
      case "soundtrack":
        return MusicalNoteIcon;
      case "conditional-links-stats":
        return ArrowRightOnRectangleIcon;
    }
  })();

  return (
    <span className="flex flex-row gap-1 items-center">
      <Icon className="w-5 h-5" />
      <span>{name}</span>
      {optional && <span className="text-gray-400">(optional)</span>}
    </span>
  );
};

export function AudioBook() {
  const { chapterId } = useParams();
  const navigate = useNavigate();
  const [startChapter, setStartChapter] = useStateLS<string | null>(
    `start-chapter-${chapterId}`,
    null,
  );
  const [currentStats, setCurrentStats] = useState<Stats>(null);
  const [currentBackground, setCurrentBackground] = useState<string>(null);
  const [currentSoundtrack, setCurrentSoundtrack] = useState<string>(null);
  const [currentProgress, setCurrentProgress] = useState<number>(null);
  const [history, setHistory] = useState<HistoryStep[]>([]);
  const controlsRef = useRef(null);
  const { data: book, error } = useSWR<Book, any, string>(
    `/api/books/chapters/${chapterId}/generate/`,
    (url) => bookFetcher(url),
  );
  const lastStep = history ? history[history.length - 1] : null;
  const [lastCheckpoint, setLastCheckpoint] = useState<string | null>(null);
  const [checkpointStats, setCheckpointStats] = useState<Stats>(null);
  const isStartChapterValid =
    book &&
    startChapter &&
    Object.values(book.chapters).some((c) => c.originalId === startChapter);

  if (book && !startChapter) {
    setStartChapter(book.chapters[book.start].originalId);
  }

  function startGame() {
    const startChapterId = Object.keys(book.chapters).find(
      (cid) => book.chapters[cid].originalId === startChapter,
    );
    setCurrentStats(book.initialStats);
    changeChapter(startChapterId, null);
  }
  const moveForward = () => {
    if (lastStep.type !== "page") {
      throw Error();
    }

    const chapter = book.chapters[lastStep.chapterId];
    if (lastStep.position + 1 < chapter.pages.length) {
      setHistory(
        history.concat({
          type: "page",
          chapterId: lastStep.chapterId,
          position: lastStep.position + 1,
        }),
      );
    }

    controlsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const changeChapter = (
    chapterId: string,
    text: string | null,
    subType: "choice" | "roll" = "choice",
  ) => {
    let newHistory: HistoryStep[] = [];
    const chapter = book.chapters[chapterId];
    if (text) {
      newHistory.push({
        type: "info",
        subType: subType,
        text: text,
      });
    }

    if (chapter.checkpoint && lastCheckpoint !== chapterId) {
      newHistory.push({
        type: "info",
        subType: "checkpoint",
        text: "You reached a checkpoint",
      });
      setLastCheckpoint(chapterId);
      setCheckpointStats(currentStats);
    }

    newHistory.push({
      type: "page",
      chapterId: chapterId,
      position: 0,
    });

    if (chapter.backgroundMusic) {
      setCurrentBackground(chapter.backgroundMusic);
    }
    if (chapter.soundtrack) {
      setCurrentSoundtrack(chapter.soundtrack);
    }
    setCurrentProgress(chapter.progress);
    setHistory(history.concat(newHistory));
    controlsRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const roll = (next: NextRoll) => {
    console.log(`Rolling 1d${next.dice}`);

    let result = Math.round(Math.random() * next.dice);
    console.log(`Rolled ${result}`);

    let pickedOutcome = null;
    for (let outcome of next.outcomes) {
      if (outcome.upTo == null || result <= outcome.upTo) {
        pickedOutcome = outcome;
        break;
      }
    }

    if (pickedOutcome == null) {
      throw new Error("Could not pick correct outcome");
    }

    console.log("Picked outcome", pickedOutcome);

    changeChapter(
      pickedOutcome.chapter,
      `You got ${result} on a 1d${next.dice} roll`,
      "roll",
    );
  };

  const restartFromLastCheckpoint = () => {
    if (!lastCheckpoint) {
      setHistory(
        history.concat({
          type: "info",
          subType: "error",
          text: "There is no checkpoint to restart from",
        }),
      );
      return;
    }

    setCurrentStats(checkpointStats);
    changeChapter(lastCheckpoint, null);
  };

  const applyEffect = (effect: PageData["effect"]) => {
    let newStats: Stats = { ...currentStats };
    newStats[effect.stat] += effect.modifier;

    if (effect.stat === "health" && newStats[effect.stat] > 10) {
      newStats[effect.stat] = 10;
    }

    setCurrentStats(newStats);
  };

  if (!book) {
    return (
      <div className="border rounded border-gray-700 bg-gray-900 p-5 mt-5">
        {!error && (
          <div className="flex justify-center">
            <Loading size="lg" />
          </div>
        )}
        {error && (
          <div className="flex flex-col gap-2 text-red-500">
            <p>Error! It was not possible to generate a playable story.</p>
            {error.info && <pre>{error.info}</pre>}
            <p>The validation page might give more information.</p>
            <div>
              <Button
                color="neutral"
                onClick={() => navigate(`/validate/${chapterId}`)}
              >
                Validate
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="border rounded border-gray-700 bg-gray-900 p-5 mt-5">
        <div className="flex flex-col gap-2">
          <h1 className="text-lg font-bold">{book.title}</h1>
          <div>
            <p>{book.summary}</p>
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="font-bold">Features needed on mobile app:</h3>
            <ul>
              {book.featuresRequired.map((name) => (
                <li key={name}>
                  <Feature name={name} />{" "}
                </li>
              ))}
              {book.featuresOptional.map((name) => (
                <li key={name}>
                  <Feature name={name} optional />{" "}
                </li>
              ))}
              {book.featuresOptional.length + book.featuresRequired.length ===
                0 && (
                <li className="italic">
                  No specific features are needed on mobile app to play this
                  story.
                </li>
              )}
            </ul>
          </div>
          {history.length === 0 && (
            <div className="flex flex-row gap-2">
              <Button
                color="neutral"
                onClick={startGame}
                disabled={!isStartChapterValid}
              >
                Start!
              </Button>
              <InputWithSearch
                initialChoice={startChapter}
                defaultChoice={book.chapters[book.start].originalId}
                choices={Object.values(book.chapters).map(
                  (chapter) => chapter.originalId,
                )}
                onChange={setStartChapter}
                label="from:"
              />
            </div>
          )}
          {history.length > 0 && (
            <>
              <div className="inset-0 my-4 flex items-center">
                <div className="w-full border-t border-gray-700" />
              </div>
              <div className="flex flex-col gap-4">
                {history.map((el, idx) => {
                  const key =
                    el.type === "page"
                      ? `${el.chapterId}-${el.position}`
                      : `info-${el.text}`;
                  return (
                    <AudioBookStep
                      key={`${idx}-${key}`}
                      step={el}
                      book={book}
                      applyEffect={applyEffect}
                    />
                  );
                })}
              </div>
            </>
          )}

          {lastStep && (
            <div ref={controlsRef}>
              <AudioBookNext
                step={lastStep}
                book={book}
                moveForward={moveForward}
                changeChapter={changeChapter}
                roll={roll}
                restartFromLastCheckpoint={restartFromLastCheckpoint}
                currentStats={currentStats}
              />
            </div>
          )}

          {history.length > 0 && (
            <>
              <div className="inset-0 my-4 flex items-center">
                <div className="w-full border-t border-gray-700" />
              </div>

              <div className="flex flex-col justify-center items-center italic text-sm">
                <div>
                  <AudioBookStats currentStats={currentStats} />
                </div>
                {currentBackground && (
                  <div>Background playing: {currentBackground}</div>
                )}
                {currentSoundtrack && (
                  <div>Music playing: {currentSoundtrack}</div>
                )}
                {currentProgress != null && (
                  <div>Progress: {(currentProgress * 100).toFixed(2)}%</div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
